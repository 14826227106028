<template>
  <el-tabs v-model="activeName">
    <el-tab-pane label="服务列表" name="service-list">
      <service-repository-list v-if="activeName == 'service-list'" list-type="platform" sku-type="2" />
    </el-tab-pane>
    <el-tab-pane label="应用市场" name="app-store">
      <service-repository-list v-if="activeName == 'app-store'" list-type="platform" sku-type="1" />
    </el-tab-pane>
  </el-tabs>
</template>
<script>
import { defineComponent, ref } from 'vue';
import ServiceRepositoryList from '../list/List.vue';
export default defineComponent({
  components: {
    ServiceRepositoryList,
  },
  setup() {
    const activeName = ref('service-list');
    return {
      activeName,
    };
  },
});
</script>
